import {FormikContextType} from 'formik'

import {EventModel} from '../../../../../../../models/ems/EventModel'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../../../../setup'
import {useBookingFormData} from '../../hook/useBookingFormData'

import {
  BookingWizardBulkProductVenueStepFormValues,
  BulkBookingFormValues,
} from '../../../../../../../models/booking-wizard/BulkBookingWizard'
import {BookingWizardSharedBulkProductVenueStep} from '../../../../../../../components/BookingWizard/BookingWizardSharedSteps/BookingWizardSharedBulkProductVenueStep'

export interface BookingWizardBulkProductVenueStepProps<
  T extends BookingWizardBulkProductVenueStepFormValues
> {
  formik: FormikContextType<T>
  event?: EventModel | null
}

export const BookingWizardBulkProductVenueStep = <
  T extends BookingWizardBulkProductVenueStepFormValues
>({
  formik,
  event,
}: BookingWizardBulkProductVenueStepProps<T>) => {
  const {productSearchResults, refreshProductsList, searchVouchers, voucherSearchResults} =
    useBookingFormData({
      eventCode: event?.code || formik.values.eventCode,
    })
  const bookingBulkForm: BulkBookingFormValues = useSelector<RootState>(
    ({customerPortal}) => customerPortal.bookingBulkForm
  ) as BulkBookingFormValues

  return (
    <BookingWizardSharedBulkProductVenueStep
      formik={formik}
      bookingBulkForm={bookingBulkForm}
      searchVouchers={searchVouchers}
      refreshProductsList={refreshProductsList}
      productSearchResults={productSearchResults}
      voucherSearchResults={voucherSearchResults}
      isPortal={true}
    />
  )
}
