import type {FormikContextType} from 'formik'
import {useMemo} from 'react'
import * as Yup from 'yup'
import {ImageInputValue} from '../../../../../../components/inputs/FileInput/ImageInputValue'
import {SelectInputItem} from '../../../../../../components/inputs/SelectInput'
import {TextInput} from '../../../../../../components/inputs/TextInput'
import {ISOCountryModel} from '../../../../../../models/ISOCountryModel'
import {useCustomerAuth} from '../../../../hooks/useCustomerAuth'
import {ExistCustomerSearchForm} from '../../../DrawerForm/CustomerFormDrawer/ExistedCustomerSearchForm'
import {CustomerModel} from '../../../../../../models/CustomerModel'
export interface CustomerFormValues {
  salutation: string | null
  firstName: string
  lastName: string
  email: string
  type: string
  avatar: ImageInputValue | null
  mobile: string
  gender: string
  nationality: string
  residence: string
  dob: Date | null
  addressName: string
  addressLine1: string
  addressLine2: string
  addressCity: string
  addressStateOrProvince: string
  addressPostCode: string
  addressCountryCode: string
  bio: string | null
  parentCode?: string
}

const requiredIfHasAddress = (value?: string, context?: Yup.TestContext<unknown>) => {
  const {
    addressLine1,
    addressLine2,
    addressCity,
    addressCountryCode,
    addressName,
    addressPostCode,
    addressStateOrProvince,
  } = context?.parent as CustomerFormValues

  const addressFields = {
    addressLine1,
    addressLine2,
    addressCity,
    addressCountryCode,
    addressName,
    addressPostCode,
    addressStateOrProvince,
  }

  const hasAddressField = Object.values(addressFields).some((value) => Boolean(value))

  if (hasAddressField) {
    return Boolean(value)
  }

  return true
}

export const roleFormValidationSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Minimum 2 characters').required('First Name is required.'),
  lastName: Yup.string().min(2, 'Minimum 2 characters').required('Last Name is required.'),
  email: Yup.string().email().required('Email is required.'),
  type: Yup.string().required('Type is required.'),
  mobile: Yup.string(),
  addressLine1: Yup.string().test(
    'required-if-has-address',
    'Address Line 1 is Required',
    requiredIfHasAddress
  ),
  addressLine2: Yup.string().test(
    'required-if-has-address',
    'Fill out line 1 first!',
    (value, context) => {
      const values = context.parent as CustomerFormValues
      if (value && !values.addressLine1) {
        return false
      }
      return true
    }
  ),
  addressCity: Yup.string().test(
    'required-if-has-address',
    'City is required',
    requiredIfHasAddress
  ),
  addressCountryCode: Yup.string().test(
    'required-if-has-address',
    'Country code is required',
    requiredIfHasAddress
  ),
})

export interface CustomerFormProps {
  formik: FormikContextType<CustomerFormValues>
  countries: ISOCountryModel[]
  editing?: boolean
  setIsExistedCustomer?: (customer?: CustomerModel) => void
  isExistedCustomer?: CustomerModel
  disabledFields?: Partial<Record<keyof CustomerFormValues, boolean>>
}

export const CustomerForm = ({
  formik,
  editing,
  isExistedCustomer,
  setIsExistedCustomer,
}: CustomerFormProps) => {
  const auth = useCustomerAuth()

  const customerTypeList = useMemo(() => {
    const CUSTOMER_TYPE: SelectInputItem[] = []
    const user = auth?.getUser()
    switch (user?.type) {
      case 'reseller':
        CUSTOMER_TYPE.push({
          label: 'Customer',
          value: 'customer',
        })
        break
      case 'partner':
        CUSTOMER_TYPE.push({
          label: 'Customer',
          value: 'customer',
        })
        break

      default:
        break
    }

    return CUSTOMER_TYPE
  }, [auth])

  return (
    <div className='container'>
      <div className='row'>
        {!editing && (
          <div className='col-12'>
            <ExistCustomerSearchForm
              customerTypeList={customerTypeList}
              formik={formik}
              setIsExistedCustomer={setIsExistedCustomer}
              isTypeDisabled={true}
            />
          </div>
        )}
        <div className='col-12 col-md-6'>
          <TextInput
            label='First Name'
            errorMessage={formik.errors.firstName}
            isTouched={formik.touched.firstName}
            placeholder='Enter First Name'
            disabled={isExistedCustomer && isExistedCustomer.code !== ''}
            {...formik.getFieldProps('firstName')}
          />
        </div>
        <div className='col-12 col-md-6'>
          <TextInput
            label='Last Name'
            errorMessage={formik.errors.lastName}
            isTouched={formik.touched.lastName}
            placeholder='Enter Last Name'
            disabled={isExistedCustomer && isExistedCustomer.code !== ''}
            {...formik.getFieldProps('lastName')}
          />
        </div>
        <div className='col-12 col-md-6'>
          <TextInput
            label='Email'
            errorMessage={formik.errors.email}
            isTouched={formik.touched.email}
            placeholder='Enter Email'
            disabled={isExistedCustomer && isExistedCustomer.code !== ''}
            {...formik.getFieldProps('email')}
          />
        </div>
        <div className='col-12 col-md-6'>
          <TextInput
            label='Mobile Number'
            errorMessage={formik.errors.mobile}
            isTouched={formik.touched.mobile}
            placeholder='Enter Mobile Number'
            disabled={isExistedCustomer && isExistedCustomer.code !== ''}
            {...formik.getFieldProps('mobile')}
          />
        </div>
        {/* <div className='col-12 col-md-6'>
          <SelectInput
            placeholder='Select Type'
            label='Type'
            items={customerTypeList}
            disabled={(isExistedCustomer && isExistedCustomer.code !== '') || disabledFields?.type}
            {...formik.getFieldProps('type')}
          />
        </div> */}

        {/* <div className='col-12 col-md-6'>
          <SelectInput
            placeholder='Select Gender'
            label='Gender'
            items={GENDER_SELECT_ITEMS}
            disabled={isExistedCustomer && isExistedCustomer.code !== ''}
            {...formik.getFieldProps('gender')}
          />
        </div> */}
        {/* <div className='col-12 col-md-6'>
          <SelectInput
            placeholder='Select Nationality'
            label='Nationality'
            items={countryList}
            disabled={isExistedCustomer && isExistedCustomer.code !== ''}
            {...formik.getFieldProps('nationality')}
          />
        </div> */}
        {/* <div className='col-12 col-md-6'>
          <SelectInput
            placeholder='Select Residence'
            label='Residence'
            items={countryList}
            disabled={isExistedCustomer && isExistedCustomer.code !== ''}
            {...formik.getFieldProps('residence')}
          />
        </div>
        <div className='col-12 col-md-6'>
          <DatePickerInput
            label='Date of Birth'
            onChange={handleDobChange}
            value={formik.values.dob}
            disabled={isExistedCustomer && isExistedCustomer.code !== ''}
          />
        </div>
        <div className='col-12 col-md-6'>
          <AvatarImageInput
            className='mb-5'
            value={formik.values.avatar}
            onChange={handleAvatarChange}
          />
        </div>
        <div className='col-12'>
          <div className='my-5'>
            <HorizontalDivider />
          </div>
          <h2 className='mb-5'>Address</h2>
        </div>
        <div className='col-12 col-md-6'>
          <TextInput
            label='Name'
            errorMessage={formik.errors.addressName}
            isTouched={formik.touched.addressName}
            placeholder='Enter Name'
            disabled={isExistedCustomer && isExistedCustomer.code !== ''}
            {...formik.getFieldProps('addressName')}
          />
        </div>
        <div className='col-12 col-md-6'>
          <TextInput
            label='Line 1'
            errorMessage={formik.errors.addressLine1}
            isTouched={formik.touched.addressLine1}
            placeholder='Enter Address Line 1'
            disabled={isExistedCustomer && isExistedCustomer.code !== ''}
            {...formik.getFieldProps('addressLine1')}
          />
        </div>
        <div className='col-12 col-md-6'>
          <TextInput
            label='Line 2'
            errorMessage={formik.errors.addressLine2}
            isTouched={formik.touched.addressLine2}
            placeholder='Enter Address Line 2'
            disabled={isExistedCustomer && isExistedCustomer.code !== ''}
            {...formik.getFieldProps('addressLine2')}
          />
        </div>
        <div className='col-12 col-md-6'>
          <TextInput
            label='City'
            errorMessage={formik.errors.addressCity}
            isTouched={formik.touched.addressCity}
            placeholder='Enter City'
            disabled={isExistedCustomer && isExistedCustomer.code !== ''}
            {...formik.getFieldProps('addressCity')}
          />
        </div>
        <div className='col-12 col-md-6'>
          <TextInput
            label='State or Province'
            errorMessage={formik.errors.addressStateOrProvince}
            isTouched={formik.touched.addressStateOrProvince}
            placeholder='Enter State or Province'
            disabled={isExistedCustomer && isExistedCustomer.code !== ''}
            {...formik.getFieldProps('addressStateOrProvince')}
          />
        </div>
        <div className='col-12 col-md-6'>
          <SelectInput
            label='Country'
            placeholder='Select Country'
            items={countryList}
            disabled={isExistedCustomer && isExistedCustomer.code !== ''}
            {...formik.getFieldProps('addressCountryCode')}
          />
        </div>
        <div className='col-12 col-md-6'>
          <TextInput
            label='Post Code'
            errorMessage={formik.errors.addressPostCode}
            isTouched={formik.touched.addressPostCode}
            placeholder='Post Code'
            disabled={isExistedCustomer && isExistedCustomer.code !== ''}
            {...formik.getFieldProps('addressPostCode')}
          />
        </div> */}
      </div>
    </div>
  )
}
