import clsx from 'clsx'
import {useMemo, useState} from 'react'
import {Helmet} from 'react-helmet'
import {Redirect, Route, Switch, 
  // Link
} from 'react-router-dom'
// import {
//   CustomerPortalAuthBackground,
//   CustomerPortalAuthBackgroundMobile,
// } from '../../../../config/logos'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useBreakpoint} from '../../../components/hooks/useBreakpoint'
import FirstLoginPasswordResetForm from '../components/FirstLoginPasswordReset'
import {ForgotPassword} from '../components/forms/ForgotPassword'
import {Login} from '../components/Login'
// import {Login} from '../components/Login'
import {MyPassLogin} from '../components/MyPassLogin'
import {useCustomerAuth} from '../hooks/useCustomerAuth'

export const AuthPage = () => {
  const {down} = useBreakpoint()
  const auth = useCustomerAuth()
  const [isOTP, setIsOTP] = useState<boolean>(false)

  const isMobile = useMemo(() => {
    return down('xs')
  }, [down])

  // const image = useMemo(() => {
  //   if (isMobile) {
  //     return CustomerPortalAuthBackgroundMobile.src
  //   }
  //   return CustomerPortalAuthBackground.src
  // }, [isMobile])

  return (
    <div className='reseller-container'>
      <div className='d-flex rs-main-bg flex-column flex-column-fluid bgi-position-y-bottom bgi-position-x-center bgi-repeat-x bgi-size-contain bgi-attachment-fixed'>
        <Helmet>
          <title>Yas Marina Circuit</title>
          <link rel='icon' href='/portal.ico' />
        </Helmet>
        <div className='d-flex flex-center flex-column flex-column-fluid p-0 min-h-100vh my-5 py-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/saas/admm-customer-portal-2023.png')}
            className='img-fluid h-100 w-250px w-100 mb-15'
          />
          <div style={{textAlign: 'center'}}>
            {!isOTP && (
              <>
                <h1 className='text-white fw-bolder mb-12'>Abu Dhabi GP 2023 Ticket Portal</h1>
                <h6 className='fw-bolder mb-5'>**Important Login Information**</h6>
                <p className='fw-bolder w-450px mb-5 fs-7'>
                  Welcome to the Special 15th Edition Formula 1® Etihad Airways Abu Dhabi Grand Prix
                  — You’re one step closer to the most exciting event of the year! To access your
                  tickets, please log in using the credentials provided in our previous email.
                  Please note that the provided password will expire 30 minutes after delivery;
                  simply click on reset password to access your tickets.
                </p>
                <p className='fw-bolder w-450px w-100 mb-8 fs-7'>
                  Thank you for your support, we look forward to hosting your epic experience!
                </p>
              </>
            )}
            {isOTP && (
              <>
                <p className='fw-bolder w-450px w-100 mb-8 fs-7'>
                  To obtain your OTP, please use your preferred Two-Factor Authentication (2FA) App.
                  In case you do not have a 2FA App, we suggest using Microsoft Authenticator or
                  Google Authenticator. You can download one for free from your system's app store.
                </p>
              </>
            )}
          </div>
          <div className='w-sm-500px w-300px opacity-75 rounded shadow-sm p-2 p-lg-4 mx-auto form-selector'>
            {auth?.isFirstLogin() && <Redirect to='/auth/password-reset' />}
            {auth && !auth.isFirstLogin() && <Redirect to='/' />}
            <Switch>
              <Route path='/auth/login/:hash'>
                {process.env.REACT_APP_WEBNTECH_LOGIN &&
                process.env.REACT_APP_WEBNTECH_LOGIN === 'FALSE' ? (
                  <MyPassLogin />
                ) : (
                  <div className='bg-light-primary p-5 '>
                    <Login setOTPState={setIsOTP} />
                  </div>
                )}
              </Route>
              <Route path='/auth/login'>
                {process.env.REACT_APP_WEBNTECH_LOGIN &&
                process.env.REACT_APP_WEBNTECH_LOGIN === 'FALSE' ? (
                  <MyPassLogin />
                ) : (
                  <div className='bg-light-primary p-5'>
                    <Login setOTPState={setIsOTP} />
                  </div>
                )}
              </Route>

              <Route path='/auth/password-reset'>
                <div className='bg-light-primary p-5'>
                  <FirstLoginPasswordResetForm />
                </div>
              </Route>
              <Route path='/auth/forgot-password'>
                <div className='bg-light-primary p-5'>
                  <ForgotPassword />
                </div>
              </Route>
              <Redirect from='/auth' exact={true} to='/auth/login' />
              <Redirect to='/auth/login' />
            </Switch>

            <div style={{textAlign: 'center'}}>
              <label className='fw-bolder w-450px w-100 mt-3 fs-7'>
                Experiencing login difficulties?{' '}
                <a href='https://www.yasmarinacircuit.com/en/contact-us' target='_blank' rel="noreferrer">
                  Click here
                </a>{' '}
                to contact us by email or call us directly at: +971 (0)2 497 9000
              </label>
            </div>
          </div>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/saas/partner-logos.png')}
            className={clsx('mt-7 img-fluid')}
            style={{
              width: isMobile ? '310px' : '520px',
            }}
          />

          {/* <img alt={PoweredBy.alt} src={PoweredBy.src} className='h-30px mt-10' />  */}
        </div>
      </div>
    </div>
  )
}
