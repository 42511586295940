import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react'
import {Button} from '../../../inputs/Button'
import {MetronicIconButton} from '../../../inputs/MetronicIconButton'
import {FilterSearchableSelectInput} from '../../../inputs/SearchableSelect/FilterSearchableSelectInput'
import {SelectInputItem} from '../../../inputs/SelectInput'
import {SweetAlert} from '../../../modals/SweetAlert'
import {LoadingSpinner} from '../../../utils/LoadingSpinner'
import {ProductModel} from '../../../../models/ems/ProductModel'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {ProductVoucherModalType} from '../../../../models/booking-wizard/BookingWizard'
import {
  ProductVoucherBulkModel,
  BulkBookingFormValues,
} from '../../../../models/booking-wizard/BulkBookingWizard'
import {VoucherModel} from '../../../../models/svc/VoucherModel'
import clsx from 'clsx'
import {useAlerts} from '../../../alerts/useAlerts'

interface AddBulkProductVoucherProps {
  modalType: ProductVoucherModalType
  onModalClose: () => void
  onAdd: (type: ProductVoucherModalType, value: ProductVoucherBulkModel) => void
  productSearchResults?: GlobalSearchModel<ProductModel> | null
  refreshProductsList: (filter?: FilterModel) => void
  productsValues: ProductVoucherBulkModel | null
  voucherSearchResults?: GlobalSearchModel<VoucherModel>
  vouchersValues: ProductVoucherBulkModel | null
  searchVouchers: (filter?: FilterModel) => void
  isPortal?: boolean
  bookingBulkForm?: BulkBookingFormValues
}

export const AddBulkProductVoucher = ({
  modalType,
  onModalClose,
  onAdd,
  productSearchResults,
  refreshProductsList,
  productsValues,
  searchVouchers,
  vouchersValues,
  voucherSearchResults,
  isPortal,
  bookingBulkForm,
}: AddBulkProductVoucherProps) => {
  const [value, setValue] = useState<ProductVoucherBulkModel | null>(
    modalType === 'product' ? productsValues : vouchersValues
  )
  const [product, setProduct] = useState<ProductModel | null>(null)
  const [voucher, setVoucher] = useState<VoucherModel | null>(null)
  const {pushError} = useAlerts()

  useEffect(() => {
    if (!modalType) {
      setProduct(null)
      setVoucher(null)
    }
  }, [modalType])

  const isProduct = useMemo(() => {
    return modalType === 'product'
  }, [modalType])

  const handleProductQTYComputation = useCallback(
    async (newValue: ProductVoucherBulkModel) => {
      if (newValue?.qty && bookingBulkForm?.customers && newValue?.remainingQty) {
        const diff = newValue.remainingQty - bookingBulkForm?.customers?.length * newValue?.qty
        if (diff < 0) {
          pushError(
            new Error(
              `Warning: Overall quantity is not enough for ${bookingBulkForm?.customers.length} customer/s`
            )
          )
          newValue.qty = 1
          setValue(newValue)
          // return newValue
        } else {
          setValue(newValue)
          // return newValue
        }
      } else {
        setValue(newValue)
      }
    },
    [pushError, bookingBulkForm?.customers]
  )

  const handleCountChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const count = e.target.value
      if (value) {
        const newValue: ProductVoucherBulkModel = {...value, qty: Number(count)}
        if (isPortal) {
          await handleProductQTYComputation(newValue)
        } else {
          setValue(newValue)
        }
      }
    },
    [value, isPortal, handleProductQTYComputation]
  )

  const handleProductChange = useCallback(
    async (value) => {
      if (isProduct) {
        setProduct(value)
      }

      if (!isProduct) {
        setVoucher(value)
      }
      const newValue: ProductVoucherBulkModel = {
        code: value.code,
        name: value.name,
        qty: 1,
        isSeated: value?.isSeated ? value?.isSeated : false,
        type: isProduct ? 'product' : 'voucher',
        remainingQty: value.remainingQty,
        startedAt: value.startedAt,
        endedAt: value.endedAt,
      }
      if (isPortal) {
        await handleProductQTYComputation(newValue)
      } else {
        setValue(newValue)
      }
    },
    [isProduct, isPortal, handleProductQTYComputation]
  )

  const handleAdd = useCallback(() => {
    if (isProduct && value) onAdd(modalType, value)
    if (!isProduct && value) onAdd(modalType, value)
  }, [isProduct, modalType, onAdd, value])

  const updatedData = useMemo(() => {
    return {...value, qty: value?.qty || 0}
  }, [value])

  const customerCount = useMemo(() => {
    if (value?.qty && bookingBulkForm?.customers && value?.remainingQty) {
      return value.remainingQty - bookingBulkForm?.customers?.length * value?.qty
    }
  }, [bookingBulkForm, value])

  return (
    <SweetAlert
      showConfirmButton={false}
      open={modalType !== undefined}
      onClose={onModalClose}
      containerClassName='overflow-auto'
      customClass={{htmlContainer: 'overflow-visible'}}
    >
      {/* {isPortal && <div className='swal2-corners'></div>} */}
      {isPortal && <div className='swal2-corners' style={{width: '450px'}}></div>}
      <div
        className={clsx('h-100 d-flex flex-column', isPortal && 'position-relative p-10')}
        style={{width: isPortal ? '405px' : ''}}
      >
        <div className='d-flex flex-column flex-grow-1 text-start'>
          <div className='position-absolute top-0 end-0'>
            <MetronicIconButton
              variant='text'
              size='md'
              iconType='Navigation'
              iconName='Close'
              tooltip='Close Modal'
              onClick={onModalClose}
            />
          </div>
          <h2 className='text-start mb-5'>Select product</h2>
          <div className='mb-5'>
            <FilterSearchableSelectInput
              value={isProduct ? product : voucher}
              itemMapper={itemMapper}
              searchResult={isProduct ? productSearchResults : voucherSearchResults}
              placeholder='Select product'
              onChange={handleProductChange}
              onFilter={isProduct ? refreshProductsList : searchVouchers}
              noMargin
            />
          </div>
          <div className=''>
            <div>
              <label className='text-start text-gray-900 mb-5'>Quantity</label>
            </div>
            <div className='d-flex justify-content-between'>
              <div>
                <input
                  onChange={handleCountChange}
                  className='product-input-item-input-container__number-input form-control form-control-solid'
                  type='number'
                  value={updatedData.qty}
                  min={0}
                  max={value?.remainingQty || undefined}
                />
              </div>
              {value && value.remainingQty && customerCount && value.remainingQty > 0 ? (
                <div className='flex-grow-1'>
                  <div className='d-flex flex-column justify-content-center align-items-end fs-7 h-100'>
                    {`Remaining Quantity: ${
                      // value.remainingQty - (value?.qty * customerCount || 0)
                      customerCount
                    }`}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className='d-flex gap-5 justify-content-center mt-5'>
          {isPortal ? (
            <Button
              className='btn btn-primary align-items-center position-relative btn-cut-conrner pe-20 w-100'
              onClick={handleAdd}
            >
              <LoadingSpinner loading={false}>Save</LoadingSpinner>
            </Button>
          ) : (
            <>
              <Button variant='primary' onClick={handleAdd}>
                <LoadingSpinner loading={false}>Save</LoadingSpinner>
              </Button>
              <Button className='me-1' onClick={onModalClose}>
                Close
              </Button>
            </>
          )}
        </div>
      </div>
    </SweetAlert>
  )
}

const itemMapper = (data: ProductModel | VoucherModel): SelectInputItem => {
  return {
    label: data.name || '',
    value: data.code || '',
  }
}
