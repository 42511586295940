import clsx from 'clsx'
import {FormikContextType} from 'formik'
import {ReactNode, useMemo} from 'react'
import {Button} from '../../inputs/Button'
import {MetronicIcon, MetronicIconPath} from '../../inputs/MetronicIcon'
import {NumberStepperProps} from '../../layouts/Stepper/NumberStepper'
import {Spinner} from '../../utils/Spinner'
import {useWizardFormikHelpers} from './useEventWizardHelpers'
import {useWizardControl} from './WizardControlProvider'

export interface WizardStep {
  title: string
  description: string
  state?: NumberStepperProps['state']
  icon: MetronicIconPath
  fields?: string[]
  skippable?: boolean
  hidden?: boolean
}

export interface WizardControlsProps {
  steps: WizardStep[]
  formik: FormikContextType<any>
  submitLabel?: ReactNode
  nextLable?: string
  noErrors?: boolean
  isHasNext?: boolean
  isPreviousButton?: boolean
  onSubmit?: () => void
  nextClassName?: string
  cornerNextClassName?: string
  isCheckout?: boolean
  handleCheckAndPrint?: () => void
}

export const WizardControls = ({
  formik,
  steps,
  submitLabel = 'Submit',
  nextLable = 'Next',
  noErrors,
  isHasNext = true,
  onSubmit,
  nextClassName,
  cornerNextClassName,
  isPreviousButton = true,
  isCheckout,
  handleCheckAndPrint,
}: WizardControlsProps) => {
  const {decrementPage, incrementPage, currentPage, hasPrevious, hasNext} = useWizardControl()
  const activeStep = useMemo(() => steps[currentPage], [currentPage, steps])
  const {fieldsHasErrors, fieldsHasValues} = useWizardFormikHelpers({
    formik,
    currentStep: currentPage,
    noErrors,
  })
  const hasErrors = useMemo(() => {
    return activeStep?.fields && fieldsHasErrors(activeStep.fields)
  }, [activeStep, fieldsHasErrors])

  const hasValues = useMemo(
    () => activeStep && activeStep?.fields && fieldsHasValues(activeStep.fields),
    [activeStep, fieldsHasValues]
  )

  return (
    <div className='container-fluid d-flex justify-content-between mt-7'>
      <div className='position-relative'>
        {hasPrevious && isPreviousButton && (
          <Button
            onClick={() => {
              decrementPage()
            }}
            className={clsx('me-3 d-inline-flex align-items-center text-primary')}
            variant='text'
            disabled={formik.isSubmitting || !hasPrevious}
          >
            <MetronicIcon className='me-2 m-0' iconType='Navigation' iconName='Arrow-left' />
            Previous
          </Button>
        )}
      </div>
      <div className='position-relative'>
        {hasNext ? (
          isHasNext ? (
            <>
              <Button
                onClick={() => {
                  incrementPage()
                }}
                className={clsx(
                  'd-inline-flex align-items-center ',
                  nextClassName,
                  cornerNextClassName
                )}
                light={false}
                variant='primary'
                disabled={hasErrors || formik.isSubmitting}
              >
                {activeStep.skippable && !hasValues ? 'Skip' : nextLable}
                {formik.isSubmitting ? (
                  <Spinner className='ms-2' />
                ) : (
                  <MetronicIcon className='ms-2 m-0' iconType='Navigation' iconName='Arrow-right' />
                )}
              </Button>
            </>
          ) : null
        ) : isCheckout ? (
          <div className='d-flex gap-3'>
            <Button
              onClick={formik.submitForm}
              className='d-inline-flex align-items-center'
              light={false}
              variant='primary'
              disabled={hasErrors || formik.isSubmitting}
            >
              Check Out
              {formik.isSubmitting ? (
                <Spinner className='ms-2' />
              ) : (
                <MetronicIcon className='ms-2 m-0' iconType='Navigation' iconName='Check' />
              )}
            </Button>

            <Button
              onClick={handleCheckAndPrint}
              className='d-inline-flex align-items-center'
              light={false}
              variant='primary'
              disabled={hasErrors || formik.isSubmitting}
            >
              Check Out & print
              {formik.isSubmitting ? (
                <Spinner className='ms-2' />
              ) : (
                <MetronicIcon className='ms-2 m-0' iconType='Navigation' iconName='Check' />
              )}
            </Button>
          </div>
        ) : (
          <Button
            onClick={onSubmit ? onSubmit : formik.submitForm}
            className={clsx('d-inline-flex align-items-center', nextClassName, cornerNextClassName)}
            light={false}
            variant='primary'
            disabled={hasErrors || formik.isSubmitting}
          >
            {submitLabel}
            {formik.isSubmitting ? (
              <Spinner className='ms-2' />
            ) : (
              <MetronicIcon className='ms-2 m-0' iconType='Navigation' iconName='Check' />
            )}
          </Button>
        )}
      </div>
    </div>
  )
}
