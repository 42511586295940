import {ChangeEvent, useCallback, useMemo, useState} from 'react'
import {Button} from '../../../inputs/Button'
import {MetronicIconButton} from '../../../inputs/MetronicIconButton'
import {SweetAlert} from '../../../modals/SweetAlert'
import {LoadingSpinner} from '../../../utils/LoadingSpinner'
import {ProductVoucherModalType} from '../../../../models/booking-wizard/BookingWizard'
import {
  ProductVoucherBulkModel,
  BulkBookingFormValues,
} from '../../../../models/booking-wizard/BulkBookingWizard'
import {useAlerts} from '../../../alerts/useAlerts'

interface EditBulkProductVoucherModalProps {
  modalType: ProductVoucherModalType
  onModalClose: () => void
  onEdit: (type: ProductVoucherModalType, value: ProductVoucherBulkModel) => void
  data: ProductVoucherBulkModel
  isPortal?: boolean
  bookingBulkForm?: BulkBookingFormValues
}

export const EditBulkProductVoucherModal = ({
  modalType,
  onModalClose,
  onEdit,
  data,
  isPortal,
  bookingBulkForm,
}: EditBulkProductVoucherModalProps) => {
  const [value, setValue] = useState<ProductVoucherBulkModel>(data)
  const {pushError} = useAlerts()

  const handleProductQTYComputation = useCallback(
    async (newValue: ProductVoucherBulkModel) => {
      if (newValue?.qty && bookingBulkForm?.customers && newValue?.remainingQty) {
        const diff = newValue.remainingQty - bookingBulkForm?.customers?.length * newValue?.qty
        if (diff < 0) {
          pushError(
            new Error(
              `Warning: Overall quantity is not enough for ${bookingBulkForm?.customers.length} customer/s`
            )
          )
          newValue.qty = 1
          setValue(newValue)
          // return newValue
        } else {
          setValue(newValue)
          // return newValue
        }
      } else {
        setValue(newValue)
      }
    },
    [pushError, bookingBulkForm?.customers]
  )

  const handleCountChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const count = e.target.value
      const newValue: ProductVoucherBulkModel = {...data, qty: Number(count)}
      if (isPortal) {
        await handleProductQTYComputation(newValue)
      } else {
        setValue(newValue)
      }
    },
    [data, handleProductQTYComputation, isPortal]
  )

  const customerCount = useMemo(() => {
    if (value?.qty && bookingBulkForm?.customers && value?.remainingQty) {
      return value.remainingQty - bookingBulkForm?.customers?.length * value?.qty
    }
  }, [bookingBulkForm, value])

  const handleEdit = useCallback(() => {
    if (modalType === 'product' && value) onEdit(modalType, value)
    if (modalType === 'voucher' && value) onEdit(modalType, value)
  }, [modalType, onEdit, value])

  const updatedData = useMemo(() => {
    return {...data, qty: value.qty}
  }, [data, value])

  return (
    <SweetAlert
      showConfirmButton={false}
      open={modalType !== undefined}
      onClose={onModalClose}
      containerClassName='overflow-auto'
      customClass={{htmlContainer: 'overflow-visible'}}
    >
      <div className='swal2-corners'></div>
      <div className='h-100 d-flex flex-column position-relative p-5'>
        <div className='d-flex flex-column flex-grow-1 text-start'>
          <div className='position-absolute top-0 end-0'>
            <MetronicIconButton
              variant='text'
              size='md'
              iconType='Navigation'
              iconName='Close'
              tooltip='Close Modal'
              onClick={onModalClose}
            />
          </div>
          <h2 className='text-start mb-5'>{`Edit ${data.name || ''}`}</h2>
          <label className='text-start mb-5'>Quantity</label>
          <input
            onChange={handleCountChange}
            className='product-input-item-input-container__number-input form-control form-control-solid'
            type='number'
            value={updatedData.qty}
            min={0}
          />
          {isPortal && value && value.qty && customerCount ? (
            <div className='flex-grow-1'>
              <div className='d-flex flex-column justify-content-center align-items-end fs-7 h-100'>
                {`Remaining Quantity: ${customerCount}`}
              </div>
            </div>
          ) : null}
        </div>
        <div className='d-flex gap-5 justify-content-center mt-5'>
          <Button
            className='btn btn-primary position-relative btn-cut-conrner pe-20 w-100'
            onClick={handleEdit}
          >
            <LoadingSpinner loading={false}>Save</LoadingSpinner>
          </Button>
        </div>
      </div>
    </SweetAlert>
  )
}
