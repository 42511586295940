import {NavigationSectionMenuItem} from './drawer'
import {CUSTOMER_PORTAL_USER_GUIDE} from '../../src/config/env'
export interface NavigationSection {
  title?: string
  menus: NavigationSectionMenuItem[]
}

export interface AuthNavigationSectionMenuItem extends NavigationSectionMenuItem {
  isAdminOnly?: boolean
  subMenus?: AuthNavigationSectionMenuItem[]
  isSub?: boolean
}

export const CUSTOMER_PORTAL_MENU: AuthNavigationSectionMenuItem = {
  title: 'Customers',
  to: '/customer',
}

export const EVENTS_PORTAL_MENU: AuthNavigationSectionMenuItem = {
  title: 'Events',
  to: '/event',
}
export const EVENTS_PORTAL_USER_GUIDE: AuthNavigationSectionMenuItem = {
  title: 'User Guide',
  toExact: `${CUSTOMER_PORTAL_USER_GUIDE}`,
}

export const CUSTOMER_DELEGATE_MENU: AuthNavigationSectionMenuItem = {
  title: '',
  to: '',
}

export const PORTA_DEFAULT_LAYOUT_MENUS = [
  EVENTS_PORTAL_MENU,
  CUSTOMER_PORTAL_MENU,
  EVENTS_PORTAL_USER_GUIDE,
]
export const DELEGATE_MENU = [CUSTOMER_DELEGATE_MENU]
