import {useMemo} from 'react'
import {useBarChartTooltipContext} from '../../../../../components/charts/HorizontalBarChart/useBarChartTooltipContext'
import {PortaProductVoucherModel} from '../../../../../models/customer-portal/PortalWidgetModal'

export interface BarChartCountTooltipProps {
  data?: PortaProductVoucherModel[]
}

export const BarChartCountTooltip = ({data}: BarChartCountTooltipProps) => {
  const {key: datumKey} = useBarChartTooltipContext()

  const hoveredData = useMemo(() => {
    if (datumKey && data) {
      return data.find((item) => item.code === datumKey || item.code === datumKey)
    }
  }, [data, datumKey])

  if (!hoveredData) {
    return null
  }

  return (
    <div>
      <div className='ff-f1-bold text-secondary fs-8 mx-2 mt-4 mb-2'>{hoveredData.name}</div>
      <div className='ff-f1-bold text-center text-secondary fs-2 mb-4'>{hoveredData?.used || 0}</div>
    </div>
  )
}
