import {Redirect, Route, Switch} from 'react-router-dom'
import {useCustomerAuth} from '../../modules/customer-portal/hooks/useCustomerAuth'
import {CustomerPage} from '../../modules/customer-portal/pages/customer/CustomerPage'
import {ForgotPassword} from '../../modules/customer-portal/components/forms/ForgotPassword'
import {useMemo} from 'react'
import PortalEventPage from '../../modules/customer-portal/pages/PortalEventPage'
import {PortalEventDetail} from '../../modules/customer-portal/pages/PortalEventDetails'

export const PrivateRoutes = () => {
  const auth = useCustomerAuth()

  const redirectRoutes = useMemo(() => {
    let link = '/error/404'
    return (
      <>
        <Redirect from='/auth' to={link} />
        <Redirect exact from='/' to={link} />
      </>
    )
  }, [])

  if (!auth) {
    return <Redirect to='/auth/login' />
  } else if (auth.isFirstLogin()) {
    return <Redirect to='/auth/password-reset' />
  }
  return (
    <Switch>
      <Route path='/event/:eventCode'>
        <PortalEventDetail />
      </Route>
      <Route path='/event'>
        <PortalEventPage />
      </Route>

      <Route path='/customer' component={CustomerPage} />
      <Route path='/auth/forgot-password' component={ForgotPassword} />
      <Redirect to='/event' />
      {redirectRoutes}
    </Switch>
  )
}
