import {ChangeEvent, useCallback, useMemo, useState} from 'react'
import {CheckboxInput} from '../inputs'
import {Button} from '../inputs/Button'
import {DragDropFileInput} from '../inputs/FileInput/DragDropFIleInput/DragDropFileInput'
import {FileType} from '../inputs/FileInput/FileInput'
import {FileInputValue} from '../inputs/FileInput/FileInputValue'
import {FileInputValueCollection} from '../inputs/FileInput/FileInputValueCollection'
import {SweetAlert} from '../modals/SweetAlert'
import {Paper} from '../utils/Paper'
import {MappingTableInput, MappingTableInputProps} from '../inputs/MappingTableInput'
import {CsvParser} from '../../utils/CsvParser'
import axios from 'axios'
import {BlobUtils} from '../../utils/BlobUtils'
import {ImportMapping} from '../../utils/ImportMapping'
import clsx from 'clsx'

export type CsvModalSubmitFunction = (
  mappedValues: ImportMapping,
  data: CsvParser,
  file: FileInputValueCollection<FileInputValue>
) => void

export interface BaseCsvMapModalProps {
  sampleFile?: {
    url: string
    name: string
  }
  onSubmit: CsvModalSubmitFunction
  open: boolean
  onClose: () => void
}

export interface CsvMapModalProps extends BaseCsvMapModalProps {
  mappableKeys: MappingTableInputProps['mappableKeys']
  className?: string
}

export const CsvMapModal = ({
  onSubmit,
  onClose,
  open,
  mappableKeys,
  sampleFile,
  className,
}: CsvMapModalProps) => {
  const [parsedCsv, setParsedCsv] = useState<CsvParser>()
  const [csvFile, setCsvFile] = useState(new FileInputValueCollection<FileInputValue>())
  const [mappedValues, setMappedValues] = useState<Record<string, number>>({})
  const [hasHeaders, setHasHeaders] = useState(true)

  const fileFactory = useCallback((file: File) => {
    return new FileInputValue(file)
  }, [])

  const handleUploadCsv = useCallback(
    async (files: FileInputValueCollection<FileInputValue>) => {
      setCsvFile(files)
      const file = files.getFirst()?.getFile()
      if (file) {
        const csv = await CsvParser.fromFile(file, {hasHeaders, removeEmptyRows: true})
        setParsedCsv(csv)
      }
    },
    [hasHeaders]
  )

  const headers = useMemo(() => {
    const csvHeaders = parsedCsv?.getHeaders()
    if (csvHeaders) {
      return csvHeaders
    }

    const firstItem = parsedCsv?.getBody()[0]

    return firstItem || []
  }, [parsedCsv])

  const body = useMemo(() => {
    return parsedCsv?.getBody().slice(0, 10) || []
  }, [parsedCsv])

  const reset = useCallback(() => {
    setParsedCsv(undefined)
    setCsvFile(new FileInputValueCollection())
    setMappedValues({})
  }, [])

  const handleClose = useCallback(() => {
    reset()
    onClose()
  }, [onClose, reset])

  const handleHasHeadersCheck = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setHasHeaders(e.target.checked)
  }, [])

  const downloadSampleFile = useCallback(async () => {
    if (sampleFile) {
      const axiosInstance = axios.create()
      const {data} = await axiosInstance.get(sampleFile.url, {responseType: 'blob'})
      BlobUtils.downloadBlob(data, `${sampleFile.name}.csv`)
    }
  }, [sampleFile])

  const handleSubmit = useCallback(() => {
    if (parsedCsv) {
      onSubmit(new ImportMapping(mappedValues), parsedCsv, csvFile)
    }
  }, [mappedValues, onSubmit, parsedCsv, csvFile])

  return (
    <SweetAlert grow='fullscreen' showConfirmButton={false} open={open} onClose={handleClose}>
      <div className='swal2-corners'></div>
      <div className='h-100 d-flex flex-column position-relative px-10'>
        <div className='d-flex flex-column flex-grow-1'>
          {!parsedCsv && (
            <div className='d-flex h-100 justify-content-center flex-column align-self-center'>
              <div>
                <CheckboxInput
                  label='Has headers?'
                  onChange={handleHasHeadersCheck}
                  checked={hasHeaders}
                />
              </div>
              <div>
                <DragDropFileInput
                  accept={FileType.CSV}
                  label='Upload CSV File'
                  limit={1}
                  placeholder='Click upload or drag and drop CSV files'
                  value={csvFile}
                  onChange={handleUploadCsv}
                  fileFactory={fileFactory}
                  placeholderClassName='text-center'
                />
              </div>
            </div>
          )}
          {parsedCsv && (
            <Paper rounded className={clsx('p-5 mb-5 overflow-auto', className)}>
              <MappingTableInput
                onChange={setMappedValues}
                body={body}
                headers={headers}
                mappableKeys={mappableKeys}
                value={mappedValues}
              />
            </Paper>
          )}
        </div>
        <div className='align-self-end'>
          {parsedCsv && (
            <Button
              className='me-3'
              variant='primary'
              onClick={handleSubmit}
              disabled={mappableKeys.length !== Object.keys(mappedValues).length}
            >
              OK
            </Button>
          )}
          {parsedCsv && (
            <Button className='me-3' onClick={reset}>
              Reset
            </Button>
          )}
          {sampleFile && !parsedCsv && (
            <Button className='me-3' onClick={downloadSampleFile}>
              Download Sample File
            </Button>
          )}
          <Button onClick={handleClose}>Back</Button>
        </div>
      </div>
    </SweetAlert>
  )
}
