import {FormikContextType} from 'formik'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {concat} from 'lodash'
import {Button} from '../../inputs/Button'
import {useOnChange} from '../../hooks/useOnChange'
import {useLocalTableSearch} from '../../hooks/useLocalTableSearch'
import {map} from 'lodash'
import {
  ProductVoucherBulkTable,
  columns,
} from '../../BookingWizard/BookingWizardTables/bulkBookingTable/ProductVoucherBulkTable'

import {
  BookingWizardBulkProductVenueStepFormValues,
  BulkBookingFormValues,
  ProductVoucherBulkModel,
} from '../../../models/booking-wizard/BulkBookingWizard'

import {ProductVoucherModalType} from '../../../models/booking-wizard/BookingWizard'
import {EditBulkProductVoucherModal} from '../../BookingWizard/component/bulkBooking/EditBulkProductVoucherModal'
import {AddBulkProductVoucher} from '../../BookingWizard/component/bulkBooking/AddBulkProductVoucher'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {VoucherModel} from '../../../models/svc/VoucherModel'
import {ProductModel} from '../../../models/ems/ProductModel'
import {FilterModel} from '../../../models/FilterModel'

export interface BookingWizardSharedBulkProductVenueStepProps<
  T extends BookingWizardBulkProductVenueStepFormValues
> {
  formik: FormikContextType<T>
  bookingBulkForm?: BulkBookingFormValues
  voucherSearchResults?: GlobalSearchModel<VoucherModel>
  productSearchResults?: GlobalSearchModel<ProductModel>
  searchVouchers: (filter?: FilterModel) => Promise<void>
  refreshProductsList: (filter?: FilterModel) => Promise<void>
  isPortal?: boolean
}

export const BookingWizardSharedBulkProductVenueStep = <
  T extends BookingWizardBulkProductVenueStepFormValues
>({
  formik,
  bookingBulkForm,
  productSearchResults,
  refreshProductsList,
  searchVouchers,
  voucherSearchResults,
  isPortal,
}: BookingWizardSharedBulkProductVenueStepProps<T>) => {
  const [modalType, setModalType] = useState<ProductVoucherModalType>()
  const [currentSelectedProductVoucher, setCurrentSelectedProductVoucher] =
    useState<ProductVoucherBulkModel>()
  const [productsSelectedValues, setProductsSelectedValues] = useState<ProductVoucherBulkModel>()
  const [vouchersSelectedValues, setVouchersSelectedValues] = useState<ProductVoucherBulkModel>()

  const tableData = useMemo(() => {
    return (
      formik.values.product || formik.values.voucher
        ? concat(formik.values.product, formik.values.voucher).filter((item) => item !== null)
        : null
    ) as ProductVoucherBulkModel[] | null
  }, [formik.values.product, formik.values.voucher])

  const {searchableLocalTableData, filterSearchableLocalTableData} = useLocalTableSearch({
    data: tableData,
    columns: tableData ? (map(columns, 'field') as Array<keyof ProductVoucherBulkModel>) : null,
  })

  useEffect(() => {
    searchVouchers()
    refreshProductsList()
  }, [refreshProductsList, searchVouchers])

  const handleCloseModal = useCallback(() => {
    if (modalType === 'product') refreshProductsList()
    if (modalType === 'voucher') searchVouchers()
    setModalType(undefined)
    setCurrentSelectedProductVoucher(undefined)
    if (productsSelectedValues) {
      setProductsSelectedValues(productsSelectedValues)
    }
    if (vouchersSelectedValues) {
      setVouchersSelectedValues(vouchersSelectedValues)
    }
  }, [
    modalType,
    productsSelectedValues,
    refreshProductsList,
    searchVouchers,
    vouchersSelectedValues,
  ])

  const handleAddProductOrVoucher = useCallback(
    (type: ProductVoucherModalType, value: ProductVoucherBulkModel) => {
      handleCloseModal()
      setTimeout(() => {
        if (type === 'product') formik.setFieldValue('product', value)
        if (type === 'voucher') formik.setFieldValue('voucher', value)
      }, 200)
    },
    [formik, handleCloseModal]
  )

  const handleOnEditProductVoucher = useCallback(
    (type: ProductVoucherModalType, value: ProductVoucherBulkModel) => {
      if (type === 'product') {
        formik.setFieldValue('product', value)
        handleCloseModal()
      }

      if (type === 'voucher') {
        formik.setFieldValue('voucher', value)
        handleCloseModal()
      }
    },
    [formik, handleCloseModal]
  )

  const handleDeleteProductsVouchers = useCallback(
    (codes: string[]) => {
      let formikProductValues = formik.values.product
      let formikVoucherValues = formik.values.voucher
      for (let i = 0; i < codes.length; i++) {
        const foundProduct = formikProductValues?.code === codes[i]
        const foundvoucher = formikVoucherValues?.code === codes[i]

        if (foundProduct) {
          formik.setFieldValue('product', undefined)
          setProductsSelectedValues(undefined)
        } else if (foundvoucher) {
          formik.setFieldValue('voucher', undefined)
          setVouchersSelectedValues(undefined)
        }
      }
    },
    [formik]
  )

  const handleEditProductsVouchers = useCallback(
    (data: ProductVoucherBulkModel) => {
      if (productSearchResults && productSearchResults.data?.length) {
        const p = productSearchResults.data.find((item) => item.code === data.code)
        if (p) setModalType('product')
      }
      if (voucherSearchResults && voucherSearchResults.data?.length) {
        const v = voucherSearchResults.data.find((item) => item.code === data.code)
        if (v) setModalType('voucher')
      }

      setCurrentSelectedProductVoucher(data)
    },
    [productSearchResults, voucherSearchResults]
  )

  useOnChange(bookingBulkForm, () => {
    if (bookingBulkForm) {
      setTimeout(() => {
        if (bookingBulkForm.product) {
          setProductsSelectedValues(bookingBulkForm.product)
          formik.setFieldValue('product', bookingBulkForm.product)
        }
        if (bookingBulkForm.voucher) {
          setVouchersSelectedValues(bookingBulkForm.voucher)
          formik.setFieldValue('voucher', bookingBulkForm.voucher)
        }
      }, 200)
    }
  })

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>{/* <h5 className='mb-7 px-8'>Select product</h5> */}</div>
        <div className='d-flex gap-3 px-11 mb-3'>
          <Button
            className='btn btn-primary px-10'
            onClick={() => {
              setModalType('product')
            }}
            disabled={
              formik.values.voucher
                ? true
                : productSearchResults?.data.length === 0 || (formik.values.product ? true : false)
            }
          >
            {productSearchResults?.data.length === 0 || (formik.values.product ? true : false)
              ? 'No Products available'
              : 'Select product'}
          </Button>
          {/* <Button
            className='btn btn-primary px-10'
            onClick={() => {
              setModalType('voucher')
            }}
            disabled={
              formik.values.product
                ? true
                : voucherSearchResults?.data.length === 0 || (formik.values.voucher ? true : false)
            }
          >
            {voucherSearchResults?.data.length === 0 || (formik.values.voucher ? true : false)
              ? 'No Vouchers available'
              : 'Select Voucher'}
          </Button> */}
        </div>
        <div>
          <ProductVoucherBulkTable
            onDelete={handleDeleteProductsVouchers}
            onEdit={handleEditProductsVouchers}
            data={searchableLocalTableData}
            onFilter={filterSearchableLocalTableData}
          />
        </div>
      </div>
      {currentSelectedProductVoucher ? (
        <EditBulkProductVoucherModal
          modalType={modalType}
          onModalClose={handleCloseModal}
          data={currentSelectedProductVoucher}
          onEdit={handleOnEditProductVoucher}
          isPortal={isPortal}
          bookingBulkForm={bookingBulkForm}
        />
      ) : (
        <AddBulkProductVoucher
          modalType={modalType}
          onModalClose={handleCloseModal}
          onAdd={handleAddProductOrVoucher}
          productsValues={productsSelectedValues || null}
          productSearchResults={productSearchResults}
          refreshProductsList={refreshProductsList}
          voucherSearchResults={voucherSearchResults}
          vouchersValues={vouchersSelectedValues || null}
          searchVouchers={searchVouchers}
          isPortal={isPortal}
          bookingBulkForm={bookingBulkForm}
        />
        // <AddProductVoucherModal
        //   modalType={modalType}
        //   onModalClose={handleCloseModal}
        //   productSearchResults={productSearchResults}
        //   refreshProductsList={refreshProductsList}
        //   productsValues={productsValues}
        //   onProductChange={handleOnChangeProduct}
        //   voucherSearchResults={voucherSearchResults}
        //   vouchersValues={vouchersValues}
        //   onVoucherChange={handleVoucherChange}
        //   searchVouchers={searchVouchers}
        //   onAdd={handleAddProductOrVoucher}
        // />
      )}
    </div>
  )
}
