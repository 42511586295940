import {FormikContextType} from 'formik'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../../../../setup'
import {
  BookingWizardFinalSeatsStepFormValues,
  BulkBookingFormValues,
} from '../../../../../../../models/booking-wizard/BulkBookingWizard'
import {useCustomerAuth} from '../../../../../hooks/useCustomerAuth'
import {BookingWizardSharedBulkFinalizeStep} from '../../../../../../../components/BookingWizard/BookingWizardSharedSteps/BookingWizardSharedBulkFinalizeStep'

export interface BookingWizardBulkFinalizeStepStepProps<
  T extends BookingWizardFinalSeatsStepFormValues
> {
  formik: FormikContextType<T>
}

export const BookingWizardBulkFinalizeStep = <T extends BookingWizardFinalSeatsStepFormValues>({
  formik,
}: BookingWizardBulkFinalizeStepStepProps<T>) => {
  const auth = useCustomerAuth()

  const bookingBulkForm: BulkBookingFormValues = useSelector<RootState>(
    ({customerPortal}) => customerPortal.bookingBulkForm
  ) as BulkBookingFormValues

  return (
    <BookingWizardSharedBulkFinalizeStep
      formik={formik}
      bookingBulkForm={bookingBulkForm}
      customerCode={auth?.getUser().code}
      isPortal={true}
    />
  )
}
