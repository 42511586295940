import {useMemo} from 'react'
import {
  BarChartDatum,
  HorizontalBarChart,
} from '../../../../components/charts/HorizontalBarChart/HorizontalBarChart'
import {PortaProductVoucherModel} from '../../../../models/customer-portal/PortalWidgetModal'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import {BarChartCountTooltip} from './BookingCountPerStatus/BarChartCount'

export interface DashboardBarChartProps {
  datum?: PortaProductVoucherModel[]
  label?: string
  isFirstColumn?: boolean
}

export const DashboardBarChart = ({datum, label, isFirstColumn}: DashboardBarChartProps) => {
  const {down} = useBreakpoint()
  const isMobile = useMemo(() => {
    return down('xs')
  }, [down])

  const chartDatum = useMemo((): BarChartDatum[] => {
    if (datum) {
      return datum.map<BarChartDatum>((d) => {
        return {
          key: d.code,
          label: d.name,
          value: d.used,
        }
      })
    }

    return []
  }, [datum])

  // const numberOfBars = datum ? datum.length : 0
  // let calculatedMinHeight
  let _isFirstColumn
  if (isFirstColumn) {
    _isFirstColumn = '882px'
    // calculatedMinHeight = 800
  } else {
    _isFirstColumn = '400px'
    // calculatedMinHeight = numberOfBars > 6 ? numberOfBars * 200 : 400
  }

  return (
    <div
      style={{
        height: isMobile ? '470px' : _isFirstColumn,
        minHeight: isMobile ? '470px' : '400px',
      }}
    >
      <div className='bg-primary h-100 d-flex flex-column justify-content-center'>
        <div
          className='diagonal-cut-corner-polygon rs-gradient px-10 py-10'
          style={{
            height: isMobile ? '96%' : '90%',
            width: isMobile ? '95%' : '100%',
          }}
        >
          {label && <div className='ff-f1-bold text-white fs-4 mb-4'>{label}</div>}
          {datum && datum.length > 0 ? (
            <HorizontalBarChart
              xLabel=''
              yLabel=''
              datum={chartDatum}
              customGridColumnColor='#020f1f'
              isFirstColumn={isFirstColumn}
              tooltip={<BarChartCountTooltip data={datum} />}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: label ? '85%' : '100%',
              }}
            >
              <div className='ff-f1-bold text-center text-light text-big mb-4'>0</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
